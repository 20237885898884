@import '~bootstrap/scss/bootstrap';
@import './components/index';
@import '~react-big-calendar/lib/sass/styles';


.rdw-editor-toolbar{
    margin-bottom: 0px !important;
}

.rdw-editor-main {
    border: 1px solid #F1F1F1 !important;
    padding: 10px
}

.MuiPaper-root{
    display: grid;
}