/* LOGIN STYLES
   ----------------------------- */

.login-page-wrapper {
  position: relative;
  height: 100vh;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.login-wrap {
  width: 100%;
  max-width: 500px;
  display: block;
  margin-top: 30px;
  margin: auto;
}
.login-img {
  width: 250px;
}
